import React from 'react'
import { CenteredResponsiveContainer, Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'
import tw from 'twin.macro'

const Anchor = tw.a`text-jump-red`
export default function BugBounties() {
  return (
    <Layout>
      <SEO title="Bug Bounties"></SEO>
      <section>
        <CenteredResponsiveContainer>
          <h1 className="text-3xl mb-5">Bug Bounties</h1>
        </CenteredResponsiveContainer>
        <iframe
          src="https://hackerone.com/8761c179-1e00-4929-8c7c-da97d56f1eb1/embedded_submissions/new"
          frameBorder="0"
          title="Submit Vulnerability Report"
          style={{ border: 'none', width: '90%', minHeight: 3000 }}
          className="max-w-7xl py-4 mx-auto"
        />
        <CenteredResponsiveContainer>
          <div className="my-12">
            We can be reached at&nbsp;
            <Anchor href="mailto:securityreports@jumptrading.com">
              securityreports@jumptrading.com
            </Anchor>
          </div>
        </CenteredResponsiveContainer>
      </section>
    </Layout>
  )
}
